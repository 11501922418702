@import "../../../../styles/variable.scss";
@media only screen and (max-width: 600px) {
    //mobile view
    .container {
    }
}

@media only screen and (min-width: 600px) {
    //desktop view
    .container {
    }
}
.container {
    display: grid;
    justify-items: center;
    .actions {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
