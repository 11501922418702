@import "../../styles/variable.scss";

.footer {
    .paper {
        height: 40px;
        display: grid;
        justify-content: center;
        align-self: center;
        align-items: center;
        align-content: center;

        .text {
            text-align: center;
        }

        .link {
            text-align: center;
            cursor: pointer;

            &:hover {
                color: $primaryColor;
            }
        }
    }
}
