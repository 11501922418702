.calendar-event-detail-modal-container {
  .event-detail-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600; }
  .calendar-event-detail-modal-content-container {
    display: flex;
    border-top: 1px solid #ececec;
    .event-detail-left-section {
      padding: 15px 40px 20px 20px; }
    .event-detail-right-section {
      padding: 15px 20px 20px 40px; }
    .event-detail-status {
      text-align: center;
      background-color: #C7EBD0;
      padding: 3px;
      border-radius: 5px;
      font-size: 12px; }
    h3 {
      font-weight: 600; }
    p {
      font-size: 17px;
      margin-bottom: 30px;
      font-weight: 400; }
    a {
      text-decoration: underline; } } }


