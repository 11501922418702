@import "../../styles/variable.scss";
@media only screen and (max-width: 600px) {
    //mobile view
    .container {
        .signUpCard {
            .signUpPaper {
                width: 85vw;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    //desktop view
    .container {
        .signUpCard {
            .signUpPaper {
                width: 50vw;
            }
        }
    }
}
.container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: auto min-content;
    .signUpCard {
        display: grid;
        // grid-template-rows: min-content auto;
        justify-content: center;
        align-content: center;
        overflow: auto;

        .signUpPaper {
            padding: 10px;
            display: grid;
            min-height: max-content;
            grid-template-rows: min-content min-content 20px auto;
            margin: 70px auto;
            text-align: center;
            align-items: center;

            .title {
                padding-bottom: 5px;
                font-size: 25px;
                font-weight: 600;
                color: $fontColor;
            }

            .subtitle {
                padding-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                color: $subtitleFontColor;
            }
        }
    }
}
