@import "../../styles/variable.scss";
@media only screen and (max-width: 600px) {
    //mobile view
    .container {
        .section {
            .formPaper {
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    //desktop view
    .container {
        .section {
            .formPaper {
                width: max-content;
            }
        }
    }
}
.container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: auto min-content;
    align-items: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    background: center bottom url("../../img/cover.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    .section {
        margin: 0px 10px;
        display: grid;
        justify-items: center;
        .formPaper {
            border-radius: 5px;
            display: grid;
            justify-items: center;
            min-width: 250px;
            .loginCard {
                display: grid;
                grid-template-rows: min-content auto;
                text-align: center;
                align-items: center;
                align-content: center;
                padding: 0px 5px;
                overflow: auto;

                .logo {
                    margin-top: 20px;
                    color: $primaryColor;
                    font-weight: 800;
                    font-size: 26px;
                    padding-top: 10px;

                    img {
                        width: 180px;
                    }
                }

                .formContainer {
                    margin: 20px 0px;
                    .title {
                        color: $fontColor;
                        font-weight: bold;
                        font-size: 22px;
                        margin-bottom: 15px;
                    }

                    .forgotten {
                        font-size: 12px;
                        color: $fontColor;
                        cursor: pointer;

                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }
}
