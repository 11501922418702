.rbc-calendar {
  font-size: 0.625rem !important; }
.dashboard-calendar-container, .rbc-overlay {
  .MuiGrid-grid-md-9 {
    @media only screen and (max-width: 600px) {
      display: none; } }
  @media only screen and (max-width: 1150px) {
    .rbc-toolbar {
      justify-content: flex-start; }
    .rbc-toolbar-label {
      text-align: right !important; } }
  .rbc-toolbar {
    margin-bottom: 20px; }
  .rbc-toolbar-label {
    font-size: 22px;
    font-weight: 600; }
  .rbc-overlay-header {
    font-size: 12px;
    font-weight: 600; }
  .rbc-event {
    background-color: #C7EBD0;
    color: #000;
    font-weight: 700;
    border-color: #fff;
    border-width: 2px;
    text-align: center;
    & {}
    &:hover {
      background-color: #b6e7e8; }
    &:focus {
      background-color: #b6e7e8; }
    .rbc-event-label {
      display: none; }
    .rbc-event-content {
      font-size: 13px; } }
  .rbc-off-range-bg {
    background: #f7f7f7; }
  .rbc-today {
    background: #edfeff; }
  .rbc-show-more {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 3px; }
  .rbc-btn-group {
    button, button:active, .button:focus, .button:hover {
      background-color: #fff;
      border: 1px solid #f3f3f3;
      box-shadow: none;
      font-weight: 500;
      font-size: 11px; }
    .rbc-active, .rbc-active:active, .rbc-active:focus, .rbc-active:hover {
      border: 1px solid #f3f3f3;
      box-shadow: none;
      background-color: #C7EBD0;
      color: #fff;
      font-weight: 500; } }
  .rbc-current-time-indicator {
    height: 2px;
    background-color: #191919; }
  .rbc-agenda-table {
    background: #fff; }
  .rbc-header {
    background-color: #C7EBD0;
    color: #000 !important;
    font-size: 8pt !important; }
  .rbc-agenda-empty {
    font-size: 12px;
    background: #edfeff;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #d0f3f5; }
  .MuiGrid-grid-md-3 {
    width: 100%; }

  .rbc-month-view {
    border: none;
    background: #fff;
    padding: 8px;
    .rbc-day-bg + .rbc-day-bg {
      border: none; }
    .rbc-date-cell {
      font-size: 18px;
      margin: 3px;
      text-align: left; }
    .rbc-current {
      color: #C7EBD0; }
    .rbc-today {
      border-top: 2px solid #C7EBD0 !important; }
    .rbc-month-header {
      .rbc-header {
        padding: 5px 5px 20px 5px;
        text-align: left;
        border-left: none;
        span {
          font-size: 14px;
          font-weight: 600;
          text-align: left; } } } } }

.dashboard-calendar-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px; }

.special-day {
  border-radius: 5px;
  color: #000;
  background-color: #265985 !important; }

.rbc-events-container {
  margin: 0px !important;
  width: 80% !important; }

.rbc-time-slot {
  margin: 0px !important; }
