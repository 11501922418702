@import "../../styles/variable.scss";

.dateRange-container {
    position: relative;
    .label {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.54);
        margin-left: 10px;
        white-space: nowrap;
        position: absolute;
        z-index: 1000;
        top: -9px;
        padding: 0px 5px;
        background-color: #fafafa;
    }
    .ant-picker-small {
        padding: 6px;
        border-radius: 5px;
    }
    .ant-picker {
        background: #fafafa;

        &:hover {
            border-color: black;
        }

        &:focus {
            border-color: $primaryColor !important;
        }
    }
}
