.location-picture-component-file-upload-container {
  min-height: 180px;
  display: flex;
  align-items: center;
  flex-direction: column; }

.location-picture-component-container {
  display: flex;
  align-items: center;
  flex-direction: column; }
