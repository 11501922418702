.file-upload-form-component {
  width: 150px;
  height: 150px;
  border: none;
  margin-right: 2%;
  .ant-upload {
    background-color: #fff;
    width: 150px;
    height: 150px;
    border: none;
    .ant-upload-text {
      font-weight: 400;
      font-size: 10px !important; }
    .anticon-inbox {
      color: #C7EBD0 !important; } } }

