@media only screen and (max-width: 600px) {
    //mobile view
    .split-form {
        .row {
            .form-item {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    //desktop view
    .split-form {
        .row {
            grid-template-columns: 50% 50%;

            .form-item {
                width: 99%;
            }
        }
    }
}

.split-form {
    width: 100%;
    .row {
        display: grid;
        width: 100%;
        :first-child {
            justify-self: left !important;
        }

        :last-child {
            justify-self: right !important;
        }

        .form-item {
            margin-bottom: 10px;
            justify-self: center;
            .formControl {
                padding: 8px 0;

                input {
                    width: 100%;
                }
            }
        }
    }
}

.form {
    margin-bottom: 5px;
    .row {
        display: grid;
        width: 100%;

        .form-item {
            margin-bottom: 5px;
            width: 100%;
            .formControl {
                padding: 5px 0;
                input {
                    width: 100%;
                }
            }
        }
    }
}
