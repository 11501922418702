.feature-video-component-container {
  .main-title {
    font-weight: bold;
    text-transform: capitalize;
    min-height: 30px; }
  .feature-video-container {
    min-height: 200px;
    padding: 10px 10px 10px 0px;
    display: flex;
    overflow-x: auto;
    @media only screen and (max-width: 600px) {
      flex-direction: column; }
    &::-webkit-scrollbar {
      display: none; }
    video {
      border: 1px solid #dedede;
      @media only screen and (max-width: 600px) {
        width: 100%; } } } }
