.all-bookings-list-container {
  background-color: #fff;
  padding: 5px 20px;
  margin-left: 20px;
  border-radius: 5px;
  @media only screen and (max-width: 960px) {
    margin: auto;
    margin-top: 25px; }
  @media only screen and (max-width: 600px) {
      margin-top: 0px; }
  .all-bookings-list-container-header {
    h1 {
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid #f3f3f3; } }
  .all-bookings-list-container-scroll-view {
    overflow: auto;
    height: 60vh;
    &::-webkit-scrollbar {
      display: none; }
    .bookings-list-date-label {
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px; }
    .bookings-list-item {
      background-color: #fff;
      padding: 10px;
      border-radius: 2px;
      border: 1px solid #f3f3f3;
      margin-bottom: 12px;
      &:hover {
        border: 1px solid #C7EBD0; }
      .bookings-list-item-title {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 8px !important; }
      .bookings-list-item-status {
        text-align: center;
        background-color: #C7EBD0;
        padding: 2px 5px;
        border-radius: 2px;
        font-size: 9px; }
      span {
        font-weight: 600; }
      .time-slot-label {
        font-size: 10px;
        margin-bottom: 12px !important;
        font-weight: 500; } } } }





