.gallery-component-container {
  margin-right: -30px;
  .main-title {
    font-weight: bold;
    text-transform: capitalize;
    min-height: 30px; }
  .gallery-images-container {
    min-height: 200px;
    padding: 10px 10px 10px 0px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none; }
    .gallery-image-item {
      position: relative;
      margin-right: 2%;
      margin-top: 15px;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 3px;
        border: 1px solid #dadada; }
      .delete-icon-container {
        position: absolute;
        background-color: white;
        border-radius: 100%;
        top: 0 !important;
        right: 0;
        margin: 5px; } } } }

