.SupportTicket-chat {
    width: 100%;
    @media only screen and (max-width: 600px) {
        display: none; } }

.SupportTicket-floating-chat {
    width: 90%;
    @media only screen and (min-width: 600px) {
        display: none; } }

.SupportTicket-floating-btn-chat {
    @media only screen and (min-width: 600px) {
        display: none; } }

