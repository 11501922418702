$primaryFont: "Montserrat", sans-serif; //Title Font
$secondaryFont: "Roboto", sans-serif; //body font

$fontColor: #000;
$inverseFontColor: #fff;

$subtitleFontColor: #757575;

$primaryColor: #C7EBD0;
$secondaryColor: #ccfffb;

$errorColor: #cc2424;
$warnColor: #ff8d24;
$successColor: #00c853;

$primaryLayoutColor: #fafafa;
$secondaryLayoutColor: #ffffff;
$inverseLayoutColor: #303030;

$borderColor: #e8e8e8;
