@import "../../../../styles/variable.scss";

.container {
    display: grid;
    justify-items: center;

    .title {
        text-align: left;
        font-weight: bold;
        width: 100%;
    }
    .actions {
        margin-top: 20px;
        margin-bottom: 10px;

        button {
            margin: 5px;
        }
    }
}
