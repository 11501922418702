@import 'antd/dist/antd.css';
@import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
@import 'react-big-calendar/lib/sass/styles';
@import "./variable";
@import "./fonts.scss";
@import "./scroll-bar.scss";
@import "./form.scss";
@import "./typography.scss";

body {
    font-family: $primaryFont;
    font-size: 14px;
    margin: 0;
    height: 100%;
    background-color: $primaryLayoutColor;
}

a {
    color: inherit;
    text-decoration: none;
}

.hidden {
    display: none;
    visibility: hidden;
}

.visible {
    visibility: visible;
}
