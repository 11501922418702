.chat-component-container {
  position: relative;
  height: 85vh;
  @media only screen and (max-width: 800px) {
    height: 75vh; }
  .cs-message-input__content-editor-wrapper {
    background-color: #fff;
    border: 2px solid #f5f5f5;
    border-radius: 3px; }
  .cs-message-input__content-editor-container, .cs-message-input__content-editor {
    background-color: #fff; }

  .cs-conversation-header, .cs-conversation-header__user-name, .cs-conversation-header__info {
    background-color: #f1f1f1 !important; }

  .cs-button--send {
    color: #C7EBD0 !important; }

  .cs-message--incoming {
    border-radius: .7em .7em .7em .7em !important;
    .cs-message__content {
      background-color: #edfeff !important; } }
  .cs-message--outgoing {
    border-radius: .7em .7em .7em .7em !important;
    .cs-message__content {
      background-color: #f1f1f1 !important; } } }
