@import "../../../../styles/variable.scss";

.formContainer {
    .actions {
        margin-top: 20px;
        margin-bottom: 10px;

        button {
            width: 60%;
        }
    }
}
