@import "./variable.scss";

.title {
    font-size: 25px;
    font-weight: 600;
    color: $fontColor;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $subtitleFontColor;
}
